import { template as template_60f625bb672d4981b3aaf2c74bb58ec7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_60f625bb672d4981b3aaf2c74bb58ec7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
