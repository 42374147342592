import { template as template_26e0bf5f2c7e41b8870d91045b509723 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_26e0bf5f2c7e41b8870d91045b509723(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
