import { template as template_96133052f8ad47769fd74d645206b40c } from "@ember/template-compiler";
const FKLabel = template_96133052f8ad47769fd74d645206b40c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
